<template>
  <div>
    <v-card
      class="mb-4"
      flat>
      <v-card-text>
        <title-label
          :label="$t('message.face&line.title')"
          class="mb-4" />
        <v-row
          v-for="(message, i) in messageLists"
          :key="`tg_fb_line-${i}`"
          no-gutters>
          <v-col cols="10">
            <toggle-form
              v-model="form[message.var]"
              :text="$t(message.text)"
              :state="state[message.var]"
              @input="submit(message.var)" />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-card flat>
      <v-card-text>
        <div class="d-flex">
          <title-label
            :label="$t('message.sms&email.title')"
            class="mb-4" />
          <v-btn
            v-if="isShipnity"
            to="/?pop=credit"
            class="ml-4"
            color="primary"
            outlined>
            {{ $t('message.btn.topUpSMS') }}
          </v-btn>
        </div>
        <v-row
          v-for="(sms, i) in smsLists"
          :key="`tg_sms-${i}`"
          no-gutters>
          <v-col cols="10">
            <toggle-form
              v-model="form[sms.var]"
              :text="$t(sms.text)"
              :state="state[sms.var]"
              @input="submit(sms.var)" />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { GET_SETTING_MESSAGE, UPDATE_SETTING } from '@/resources/graphql'

export default {
  apollo: {
    setting: () => ({
      fetchPolicy: 'no-cache',
      query: GET_SETTING_MESSAGE,
      result ({ data: { setting } }) {
        this.form.facebookSendTransfer = setting.facebookSendTransfer
        this.form.smsSendTransfer = setting.smsSendTransfer
        this.form.notifyWhenShopPay = setting.notifyWhenShopPay
        this.form.confirmAddressWhenPay = setting.confirmAddressWhenPay
        this.form.notifyWhenUpdateOrder = setting.notifyWhenUpdateOrder
        this.form.autoSmsTransfer = setting.autoSmsTransfer
        this.form.autoEmail = setting.autoEmail
        this.setLoading(false)
      }
    })
  },
  data () {
    return {
      messageLists: [
        {
          text: 'message.face&line.facebookSendTransfer',
          var: 'facebookSendTransfer'
        },
        {
          text: 'message.face&line.notifyWhenShopPay',
          var: 'notifyWhenShopPay'
        },
        {
          text: 'message.face&line.confirmAddressWhenPay',
          var: 'confirmAddressWhenPay'
        },
        {
          text: 'message.face&line.notifyWhenUpdateOrder',
          var: 'notifyWhenUpdateOrder'
        }
      ],
      smsLists: [
        {
          text: 'message.sms&email.autoSmsTransfer',
          var: 'autoSmsTransfer'
        },
        {
          text: 'message.sms&email.smsSendTransfer',
          var: 'smsSendTransfer'
        },
        {
          text: 'message.sms&email.autoEmail',
          var: 'autoEmail'
        }
      ],
      state: {
        facebookSendTransfer: 'ready',
        smsSendTransfer: 'ready',
        notifyWhenShopPay: 'ready',
        confirmAddressWhenPay: 'ready',
        notifyWhenUpdateOrder: 'ready',
        autoSmsTransfer: 'ready',
        autoEmail: 'ready'

      },
      form: {
        facebookSendTransfer: null,
        smsSendTransfer: null,
        notifyWhenShopPay: null,
        confirmAddressWhenPay: null,
        notifyWhenUpdateOrder: null,
        autoSmsTransfer: null,
        autoEmail: null
      }
    }
  },
  computed: {
    domainName () {
      const host = window.location.hostname
      const parts = host.split('.')
      if (parts.length === 3) {
        return `${parts[1]}.${parts[2]}`
      } if (parts.length === 2) {
        return `${parts[0]}.${parts[1]}`
      }
      return parts[0]
    },
    isShipnity () {
      return this.domainName === 'shipnity.com' || this.domainName === 'localhost'
    }
  },
  mounted () {
    this.setLoading(true)
  },
  methods: {
    ...mapActions({
      setLoading: 'Notification/setLoading'
    }),
    async submit (field) {
      this.state[field] = 'loading'
      const res = await this.$apollo.mutate({
        mutation: UPDATE_SETTING,
        variables: {
          input: {
            [field]: this.form[field]
          }
        }
      }).catch(() => {
        this.state[field] = 'error'
      })
      if (res) {
        const { data: { updateSetting } } = res
        if (updateSetting.errors && Object.keys(updateSetting.errors).length) {
          this.state[field] = 'error'
        } else {
          this.state[field] = 'success'
        }
      }
    }
  }
}
</script>

<style>

</style>

<template>
  <div>
    <v-row no-gutters>
      <v-col>
        <v-card flat>
          <v-card-text>
            <title-label
              :label="$t('message.codMessage.title')"
              prepend-icon="mdi-email" />
            <text-editor
              v-model="form.codSummaryText"
              class="mt-3"
              :state="state['codSummaryText']"
              @submit="submit('codSummaryText')" />
          </v-card-text>
        </v-card>
      </v-col>
      <v-col
        cols="4"
        class="pl-4">
        <v-card flat>
          <v-card-text>
            <title-label
              :label="$t('message.codMessage.previewSummaryCod')"
              prepend-icon="mdi-email" />
            <p class="mt-3">
              เลขที่ใบสั่งซื้อ: MRA12
              <br />
              รายการสินค้า
              <br />
              กางเกงเด็ก ราคา 100.00 บาท
              <br />
              <br />
              ค่าส่งแบบ EMS  0.00 บาท
              <br />
              ยอดรวม 100.00 บาท
              <br />
              <br />
            </p>
            <div v-html="form.codSummaryText" />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import TextEditor from '@/views/Message/components/TextEditor.vue'
import { GET_SETTING_MESSAGE, UPDATE_SETTING } from '@/resources/graphql'

export default {
  apollo: {
    setting: () => ({
      fetchPolicy: 'no-cache',
      query: GET_SETTING_MESSAGE,
      result ({ data: { setting } }) {
        this.form.codSummaryText = setting.codSummaryText
        this.setLoading(false)
      }
    })
  },
  components: {
    TextEditor
  },
  data () {
    return {
      state: {
        codSummaryText: 'ready'
      },
      form: {
        codSummaryText: null
      }
    }
  },
  mounted () {
    this.setLoading(true)
  },
  methods: {
    ...mapActions({
      setLoading: 'Notification/setLoading'
    }),
    async submit (field) {
      this.state[field] = 'loading'
      const res = await this.$apollo.mutate({
        mutation: UPDATE_SETTING,
        variables: {
          input: {
            [field]: this.form[field]
          }
        }
      })

      if (res) {
        const { data: { updateSetting } } = res
        if (updateSetting.errors && Object.keys(updateSetting.errors).length) {
          this.state[field] = 'error'
          this.error = updateSetting.errors
        } else {
          this.state[field] = 'success'
        }
      }
    }
  }
}
</script>

<style scoped>
  .default-message {
    position: absolute;
    top: 0;
    right: 0;
  }
</style>

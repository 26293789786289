var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-card",
        {
          staticClass: "mb-4",
          staticStyle: { position: "relative" },
          attrs: { flat: "" }
        },
        [
          _c(
            "v-card-text",
            [
              _c("title-label", {
                attrs: { label: _vm.$t("message.paymentMessage.title") }
              }),
              _c("textarea-with-tag", {
                attrs: {
                  "default-value": _vm.defaultValue.smsTransferMsg,
                  state: _vm.state["smsTransferMsg"],
                  tags: _vm.tag.paymentMessage
                },
                on: {
                  submit: function($event) {
                    return _vm.submit("smsTransferMsg")
                  }
                },
                model: {
                  value: _vm.form.smsTransferMsg,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "smsTransferMsg", $$v)
                  },
                  expression: "form.smsTransferMsg"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-card",
        {
          staticClass: "mb-4",
          staticStyle: { position: "relative" },
          attrs: { flat: "" }
        },
        [
          _c(
            "v-card-text",
            [
              _c("title-label", {
                attrs: { label: _vm.$t("message.closeOrderMessage.title") }
              }),
              _c(
                "div",
                { staticClass: "mt-3" },
                [
                  _c("toggle-form", {
                    attrs: {
                      text: _vm.$t(
                        "message.closeOrderMessage.facebookSendClosedNote"
                      ),
                      state: _vm.state["facebookSendClosedNote"]
                    },
                    on: {
                      input: function($event) {
                        return _vm.submit("facebookSendClosedNote")
                      }
                    },
                    model: {
                      value: _vm.form.facebookSendClosedNote,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "facebookSendClosedNote", $$v)
                      },
                      expression: "form.facebookSendClosedNote"
                    }
                  }),
                  _c("toggle-form", {
                    attrs: {
                      state: _vm.state["autoSms"],
                      text: _vm.$t("message.closeOrderMessage.autoSms")
                    },
                    on: {
                      input: function($event) {
                        return _vm.submit("autoSms")
                      }
                    },
                    model: {
                      value: _vm.form.autoSms,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "autoSms", $$v)
                      },
                      expression: "form.autoSms"
                    }
                  })
                ],
                1
              ),
              _c("textarea-with-tag", {
                attrs: {
                  "default-value": _vm.defaultValue.smsText,
                  state: _vm.state["smsText"],
                  tags: _vm.tag.closeOrderMessage,
                  disabled:
                    !_vm.form.facebookSendClosedNote && !_vm.form.autoSms
                },
                on: {
                  submit: function($event) {
                    return _vm.submit("smsText")
                  }
                },
                model: {
                  value: _vm.form.smsText,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "smsText", $$v)
                  },
                  expression: "form.smsText"
                }
              }),
              _c("v-checkbox", {
                staticClass: "my-1",
                attrs: { "hide-details": "" },
                on: {
                  change: function($event) {
                    return _vm.submit("notifyClosedNoteWithTrackingLink")
                  }
                },
                scopedSlots: _vm._u([
                  {
                    key: "label",
                    fn: function() {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "message.closeOrderMessage.notifyClosedNoteWithTrackingLink"
                              )
                            ) +
                            " "
                        ),
                        _c("br"),
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "message.closeOrderMessage.appendNotifyClosedNoteWithTrackingLink"
                              )
                            ) +
                            " "
                        )
                      ]
                    },
                    proxy: true
                  }
                ]),
                model: {
                  value: _vm.form.notifyClosedNoteWithTrackingLink,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "notifyClosedNoteWithTrackingLink", $$v)
                  },
                  expression: "form.notifyClosedNoteWithTrackingLink"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-card",
        { staticClass: "mb-4", attrs: { flat: "" } },
        [
          _c(
            "v-card-text",
            [
              _c("title-label", {
                attrs: { label: _vm.$t("message.expireBillMessage.title") }
              }),
              _c("textarea-with-tag", {
                attrs: {
                  "default-value": _vm.defaultValue.notPaidNotifyMessage,
                  state: _vm.state["notPaidNotifyMessage"],
                  tags: _vm.tag.expireBillMessage
                },
                on: {
                  submit: function($event) {
                    return _vm.submit("notPaidNotifyMessage")
                  }
                },
                model: {
                  value: _vm.form.notPaidNotifyMessage,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "notPaidNotifyMessage", $$v)
                  },
                  expression: "form.notPaidNotifyMessage"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-card",
        {
          staticClass: "mb-4",
          staticStyle: { position: "relative" },
          attrs: { flat: "" }
        },
        [
          _c(
            "v-card-text",
            [
              _c("title-label", {
                attrs: { label: _vm.$t("message.importProductMessage.title") }
              }),
              _c("toggle-form", {
                attrs: {
                  text: _vm.$t(
                    "message.importProductMessage.productStockReminder"
                  ),
                  state: _vm.state["productStockReminder"]
                },
                on: {
                  input: function($event) {
                    return _vm.submit("productStockReminder")
                  }
                },
                model: {
                  value: _vm.form.productStockReminder,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "productStockReminder", $$v)
                  },
                  expression: "form.productStockReminder"
                }
              }),
              _c("textarea-with-tag", {
                attrs: {
                  "default-value": _vm.defaultValue.productStockMsg,
                  state: _vm.state["productStockMsg"],
                  tags: _vm.tag.importProductMessage,
                  disabled: !_vm.form.productStockReminder
                },
                on: {
                  submit: function($event) {
                    return _vm.submit("productStockMsg")
                  }
                },
                model: {
                  value: _vm.form.productStockMsg,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "productStockMsg", $$v)
                  },
                  expression: "form.productStockMsg"
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
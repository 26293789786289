var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-row",
        { attrs: { "no-gutters": "" } },
        [
          _c(
            "v-col",
            [
              _c(
                "v-card",
                { attrs: { flat: "" } },
                [
                  _c(
                    "v-card-text",
                    [
                      _c("title-label", {
                        attrs: {
                          label: _vm.$t("message.orderMessage.title"),
                          "prepend-icon": "mdi-email"
                        }
                      }),
                      _c(
                        "div",
                        { staticClass: "mt-1" },
                        [
                          _c("toggle-form", {
                            attrs: {
                              text: _vm.$t(
                                "message.orderMessage.showSummaryOrder"
                              ),
                              state:
                                _vm.state["hideOrderSummaryTextInSummaryText"]
                            },
                            on: {
                              input: function($event) {
                                return _vm.submit(
                                  "hideOrderSummaryTextInSummaryText"
                                )
                              }
                            },
                            model: {
                              value: _vm.form.hideOrderSummaryTextInSummaryText,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.form,
                                  "hideOrderSummaryTextInSummaryText",
                                  $$v
                                )
                              },
                              expression:
                                "form.hideOrderSummaryTextInSummaryText"
                            }
                          })
                        ],
                        1
                      ),
                      _c("text-field-label", {
                        attrs: {
                          label: _vm.$t("message.orderMessage.summaryOrder")
                        }
                      }),
                      _c("text-editor", {
                        attrs: {
                          state: _vm.state["orderSummaryText"],
                          disabled: !_vm.form.hideOrderSummaryTextInSummaryText
                        },
                        on: {
                          submit: function($event) {
                            return _vm.submit("orderSummaryText")
                          },
                          input: function($event) {
                            return _vm.preview("orderSummaryText")
                          },
                          focus: function($event) {
                            return _vm.preview("orderSummaryText")
                          }
                        },
                        model: {
                          value: _vm.form.orderSummaryText,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "orderSummaryText", $$v)
                          },
                          expression: "form.orderSummaryText"
                        }
                      }),
                      _c("text-field-label", {
                        attrs: {
                          label: _vm.$t("message.orderMessage.summaryLink")
                        }
                      }),
                      _c("text-editor", {
                        attrs: {
                          "default-value": _vm.defaultValue.orderLinkText,
                          state: _vm.state["orderLinkText"]
                        },
                        on: {
                          submit: function($event) {
                            return _vm.submit("orderLinkText")
                          },
                          input: function($event) {
                            return _vm.preview("orderLinkText")
                          },
                          focus: function($event) {
                            return _vm.preview("orderLinkText")
                          }
                        },
                        model: {
                          value: _vm.form.orderLinkText,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "orderLinkText", $$v)
                          },
                          expression: "form.orderLinkText"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-col",
            { staticClass: "pl-4", attrs: { cols: "4" } },
            [
              _c(
                "v-card",
                { attrs: { flat: "" } },
                [
                  _c(
                    "v-card-text",
                    [
                      _c("title-label", {
                        attrs: {
                          label: _vm.$t(
                            "message.orderMessage.previewSummaryOrder"
                          ),
                          "prepend-icon": "mdi-email"
                        }
                      }),
                      _c("p", { staticClass: "mt-3" }, [
                        _vm._v(" เลขที่ใบสั่งซื้อ: MRA12 "),
                        _c("br"),
                        _vm._v(" รายการสินค้า "),
                        _c("br"),
                        _vm._v(" กางเกงเด็ก ราคา 100.00 บาท "),
                        _c("br"),
                        _c("br"),
                        _vm._v(" ค่าส่งแบบ EMS 0.00 บาท "),
                        _c("br"),
                        _vm._v(" ยอดรวม 100.00 บาท "),
                        _c("br"),
                        _c("br")
                      ]),
                      _c("div", {
                        domProps: { innerHTML: _vm._s(_vm.previewData) }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <v-form
    ref="form"
    :readonly="isSubmitting">
    <div class="d-flex justify-end">
      <v-btn
        v-if="defaultValue"
        class="mb-1"
        rounded
        text
        @click="setToDefault()"
      >
        <v-icon
          color="primary"
          left
          small>
          mdi-reload
        </v-icon>
        <span class="primary--text">
          {{ $t("message.btn.defaultMessage") }}
        </span>
      </v-btn>
    </div>
    <div :class="{ 'tagify--disabled': disabled }">
      <div class="tagify__overlay--disabled"></div>
      <textarea
        ref="tags"
        :value="mixText"
        @change="onTagsChange" />
    </div>
    <div class="d-flex justify-space-between mt-4">
      <v-btn
        v-for="tag in tags"
        :key="`txtwithtag_${tag.value}`"
        color="primary"
        class="mr-1"
        label
        small
        outlined
        :disabled="disabled"
        @click="addTag(tag)">
        <v-icon
          left
          small>
          mdi-plus-circle
        </v-icon>
        <span>{{ tag.text }}</span>
      </v-btn>
      <v-spacer />
      <div>
        <save-change-button
          :state="state"
          :label="$t('app.btn.save')"
          :disabled="disabled"
          @click="$emit('submit')"
        />
      </div>
    </div>
  </v-form>
</template>

<script>
import Tagify from '@yaireo/tagify'
import '@yaireo/tagify/dist/tagify.css'

export default {
  props: {
    value: {
      type: [String, Array],
      default: ''
    },
    defaultValue: {
      type: String,
      default: ''
    },
    state: {
      type: String,
      validator: (val) => ['ready', 'loading', 'success', 'error'].includes(val),
      default: () => 'ready'
    },
    tags: {
      type: Array,
      default: () => []
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    mixText () {
      return this.value !== null ? this.parseStrToTags(this.value.trim()) : ''
    },
    isSubmitting () {
      return this.state === 'loading'
    }
  },
  watch: {
    mixText (newValue) {
      this.tagify.loadOriginalValues(newValue)
      this.$emit('input', this.parseTagsToStr(newValue))
    }
  },
  mounted () {
    this.tagify = new Tagify(this.$refs.tags, {
      mode: 'mix',
      pattern: /#/,
      tagTextProp: 'text',
      whitelist: this.tags,
      enforceWhitelist: true,
      duplicates: true,
      editTags: false,
      dropdown: {
        enabled: 0,
        position: 'text',
        maxItems: Infinity,
        mapValueTo: (item) => item.text,
        highlightFirst: true
      }
    })
  },
  methods: {
    parseStrToTags (str) {
      let value = str
      this.tags.forEach((obj) => {
        value = value.replaceAll(obj.value, `[[{"text":"${obj.text}","value":"${obj.value}","prefix":"#"}]]`)
      })
      return value
    },
    parseTagsToStr (str) {
      let value = str
      this.tags.forEach((obj) => {
        value = value.replaceAll(`[[{"text":"${obj.text}","value":"${obj.value}","prefix":"#"}]]`, obj.value)
      })
      return value
    },
    onTagsChange (e) {
      this.$emit('input', this.parseTagsToStr(e.target.value))
    },
    addTag (tag) {
      const value = `${this.mixText} ${this.parseStrToTags(tag.value)}`
      this.tagify.loadOriginalValues(value)
      this.$emit('input', this.parseTagsToStr(value))
    },
    setToDefault () {
      this.tagify.loadOriginalValues(this.defaultValue)
      this.$emit('input', this.defaultValue)
    }
  }
}
</script>

<style>
  .tagify {
    width: 100%;
    min-height: 100px;
  }
  .tagify--disabled {
    position: relative;
    background-color: #f1f2f5;
  }
  .tagify--disabled .tagify__overlay--disabled {
    position: absolute;
    background-color: #f1f2f5;
    opacity: 0.4;
    width: 100%;
    height: 100%;
    z-index: 2;
  }
</style>

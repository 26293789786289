<template>
  <div>
    <v-card
      style="position: relative;"
      class="mb-4"
      flat>
      <v-card-text>
        <title-label :label="$t('message.paymentMessage.title')" />
        <textarea-with-tag
          v-model="form.smsTransferMsg"
          :default-value="defaultValue.smsTransferMsg"
          :state="state['smsTransferMsg']"
          :tags="tag.paymentMessage"
          @submit="submit('smsTransferMsg')" />
      </v-card-text>
    </v-card>
    <v-card
      style="position: relative;"
      class="mb-4"
      flat>
      <v-card-text>
        <title-label :label="$t('message.closeOrderMessage.title')" />
        <div class="mt-3">
          <toggle-form
            v-model="form.facebookSendClosedNote"
            :text="$t('message.closeOrderMessage.facebookSendClosedNote')"
            :state="state['facebookSendClosedNote']"
            @input="submit('facebookSendClosedNote')" />
          <toggle-form
            v-model="form.autoSms"
            :state="state['autoSms']"
            :text="$t('message.closeOrderMessage.autoSms')"
            @input="submit('autoSms')" />
        </div>
        <textarea-with-tag
          v-model="form.smsText"
          :default-value="defaultValue.smsText"
          :state="state['smsText']"
          :tags="tag.closeOrderMessage"
          :disabled="!form.facebookSendClosedNote && !form.autoSms"
          @submit="submit('smsText')" />
        <v-checkbox
          v-model="form.notifyClosedNoteWithTrackingLink"
          class="my-1"
          hide-details
          @change="submit('notifyClosedNoteWithTrackingLink')">
          <template v-slot:label>
            {{ $t('message.closeOrderMessage.notifyClosedNoteWithTrackingLink') }}
            <br />
            {{ $t('message.closeOrderMessage.appendNotifyClosedNoteWithTrackingLink') }}
          </template>
        </v-checkbox>
      </v-card-text>
    </v-card>
    <v-card
      class="mb-4"
      flat>
      <v-card-text>
        <title-label :label="$t('message.expireBillMessage.title')" />
        <textarea-with-tag
          v-model="form.notPaidNotifyMessage"
          :default-value="defaultValue.notPaidNotifyMessage"
          :state="state['notPaidNotifyMessage']"
          :tags="tag.expireBillMessage"
          @submit="submit('notPaidNotifyMessage')" />
      </v-card-text>
    </v-card>
    <v-card
      style="position: relative;"
      class="mb-4"
      flat>
      <v-card-text>
        <title-label :label="$t('message.importProductMessage.title')" />
        <toggle-form
          v-model="form.productStockReminder"
          :text="$t('message.importProductMessage.productStockReminder')"
          :state="state['productStockReminder']"
          @input="submit('productStockReminder')" />
        <textarea-with-tag
          v-model="form.productStockMsg"
          :default-value="defaultValue.productStockMsg"
          :state="state['productStockMsg']"
          :tags="tag.importProductMessage"
          :disabled="!form.productStockReminder"
          @submit="submit('productStockMsg')" />
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import TextareaWithTag from '@/views/Message/components/TextareaWithTag.vue'
import { GET_SETTING_MESSAGE, UPDATE_SETTING } from '@/resources/graphql'

export default {
  apollo: {
    setting: () => ({
      fetchPolicy: 'no-cache',
      query: GET_SETTING_MESSAGE,
      watchLoading (isLoading) {
        this.setLoading(isLoading)
      },
      result ({ data: { setting } }) {
        this.form.smsTransferMsg = setting.smsTransferMsg
        this.form.facebookSendClosedNote = setting.facebookSendClosedNote
        this.form.autoSms = setting.autoSms
        this.form.smsText = setting.smsText
        this.form.notPaidNotifyMessage = setting.notPaidNotifyMessage
        this.form.notifyClosedNoteWithTrackingLink = setting.notifyClosedNoteWithTrackingLink
        this.form.productStockReminder = setting.productStockReminder
        this.form.productStockMsg = setting.productStockMsg

        this.defaultValue.smsTransferMsg = setting.smsTransferMsgDefault
        this.defaultValue.smsText = setting.smsTextDefault
        this.defaultValue.notPaidNotifyMessage = setting.notPaidNotifyMessageDefault
        this.defaultValue.productStockMsg = setting.productStockMsgDefault
        this.setLoading(false)
      }
    })
  },
  components: {
    TextareaWithTag
  },
  data () {
    return {
      state: {
        smsTransferMsg: 'ready',
        facebookSendClosedNote: 'ready',
        notPaidNotifyMessage: 'ready',
        autoSms: 'ready',
        smsText: 'ready',
        productStockReminder: 'ready',
        productStockMsg: 'ready'
      },
      form: {
        smsTransferMsg: null,
        facebookSendClosedNote: null,
        autoSms: null,
        smsText: null,
        notPaidNotifyMessage: null,
        notifyClosedNoteWithTrackingLink: null,
        productStockReminder: null,
        productStockMsg: null
      },
      defaultValue: {
        smsTransferMsg: null,
        smsText: null,
        notPaidNotifyMessage: null,
        productStockMsg: null
      },
      tag: {
        paymentMessage: [
          {
            text: 'เลขที่ออเดอร์',
            value: '#ORDER_NUMBER#'
          },
          {
            text: 'ลิงก์ออเดอร์',
            value: '#ORDER_LINK#'
          },
          {
            text: 'ยอดสั่งซื้อ',
            value: '#ORDER_PRICE#'
          },
          {
            text: 'ยอดที่จ่ายแล้ว',
            value: '#ORDER_PAID_AMOUNT#'
          },
          {
            text: 'ชื่อสินค้า',
            value: '#ITEM_NAME#'
          }
        ],
        closeOrderMessage: [
          {
            text: 'เลขที่ออเดอร์',
            value: '#ORDER_NUMBER#'
          },
          {
            text: 'ลิงก์ออเดอร์',
            value: '#ORDER_LINK#'
          },
          {
            text: 'ยอดสั่งซื้อ',
            value: '#ORDER_PRICE#'
          },
          {
            text: 'ยอดที่จ่ายแล้ว',
            value: '#ORDER_PAID_AMOUNT#'
          }
        ],
        expireBillMessage: [
          {
            text: 'เลขที่ออเดอร์',
            value: '#ORDER_NUMBER#'
          },
          {
            text: 'ลิงก์ออเดอร์',
            value: '#ORDER_LINK#'
          },
          {
            text: 'ยอดสั่งซื้อ',
            value: '#ORDER_PRICE#'
          },
          {
            text: 'ยอดที่จ่ายแล้ว',
            value: '#ORDER_PAID_AMOUNT#'
          }
        ],
        importProductMessage: [
          {
            text: 'ชื่อสินค้า',
            value: '#ITEM_NAME#'
          }
        ]
      }
    }
  },
  mounted () {
    this.setLoading(true)
  },
  methods: {
    ...mapActions({
      setLoading: 'Notification/setLoading'
    }),
    async submit (field) {
      this.state[field] = 'loading'
      const res = await this.$apollo.mutate({
        mutation: UPDATE_SETTING,
        variables: {
          input: {
            [field]: this.form[field]
          }
        }
      })

      if (res) {
        const { data: { updateSetting } } = res
        if (updateSetting.errors && Object.keys(updateSetting.errors).length) {
          this.state[field] = 'error'
          this.error = updateSetting.errors
        } else {
          this.state[field] = 'success'
        }
      }
    }
  }
}
</script>

<style scoped>
  .default-message {
    position: absolute;
    top: -25px;
    right: 10px;
  }
</style>

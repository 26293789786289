var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-row",
        { attrs: { "no-gutters": "" } },
        [
          _c(
            "v-col",
            [
              _c(
                "v-card",
                { attrs: { flat: "" } },
                [
                  _c(
                    "v-card-text",
                    [
                      _c("title-label", {
                        attrs: {
                          label: _vm.$t("message.codMessage.title"),
                          "prepend-icon": "mdi-email"
                        }
                      }),
                      _c("text-editor", {
                        staticClass: "mt-3",
                        attrs: { state: _vm.state["codSummaryText"] },
                        on: {
                          submit: function($event) {
                            return _vm.submit("codSummaryText")
                          }
                        },
                        model: {
                          value: _vm.form.codSummaryText,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "codSummaryText", $$v)
                          },
                          expression: "form.codSummaryText"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-col",
            { staticClass: "pl-4", attrs: { cols: "4" } },
            [
              _c(
                "v-card",
                { attrs: { flat: "" } },
                [
                  _c(
                    "v-card-text",
                    [
                      _c("title-label", {
                        attrs: {
                          label: _vm.$t("message.codMessage.previewSummaryCod"),
                          "prepend-icon": "mdi-email"
                        }
                      }),
                      _c("p", { staticClass: "mt-3" }, [
                        _vm._v(" เลขที่ใบสั่งซื้อ: MRA12 "),
                        _c("br"),
                        _vm._v(" รายการสินค้า "),
                        _c("br"),
                        _vm._v(" กางเกงเด็ก ราคา 100.00 บาท "),
                        _c("br"),
                        _c("br"),
                        _vm._v(" ค่าส่งแบบ EMS 0.00 บาท "),
                        _c("br"),
                        _vm._v(" ยอดรวม 100.00 บาท "),
                        _c("br"),
                        _c("br")
                      ]),
                      _c("div", {
                        domProps: { innerHTML: _vm._s(_vm.form.codSummaryText) }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
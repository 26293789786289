<template>
  <div>
    <v-row no-gutters>
      <v-col>
        <v-card flat>
          <v-card-text>
            <title-label
              :label="$t('message.orderMessage.title')"
              prepend-icon="mdi-email" />
            <div class="mt-1">
              <toggle-form
                v-model="form.hideOrderSummaryTextInSummaryText"
                :text="$t('message.orderMessage.showSummaryOrder')"
                :state="state['hideOrderSummaryTextInSummaryText']"
                @input="submit('hideOrderSummaryTextInSummaryText')" />
            </div>
            <text-field-label :label="$t('message.orderMessage.summaryOrder')" />
            <text-editor
              v-model="form.orderSummaryText"
              :state="state['orderSummaryText']"
              :disabled="!form.hideOrderSummaryTextInSummaryText"
              @submit="submit('orderSummaryText')"
              @input="preview('orderSummaryText')"
              @focus="preview('orderSummaryText')" />
            <text-field-label :label="$t('message.orderMessage.summaryLink')" />
            <text-editor
              v-model="form.orderLinkText"
              :default-value="defaultValue.orderLinkText"
              :state="state['orderLinkText']"
              @submit="submit('orderLinkText')"
              @input="preview('orderLinkText')"
              @focus="preview('orderLinkText')" />
          </v-card-text>
        </v-card>
      </v-col>
      <v-col
        cols="4"
        class="pl-4">
        <v-card flat>
          <v-card-text>
            <title-label
              :label="$t('message.orderMessage.previewSummaryOrder')"
              prepend-icon="mdi-email" />
            <p class="mt-3">
              เลขที่ใบสั่งซื้อ: MRA12
              <br />
              รายการสินค้า
              <br />
              กางเกงเด็ก ราคา 100.00 บาท
              <br />
              <br />
              ค่าส่งแบบ EMS  0.00 บาท
              <br />
              ยอดรวม 100.00 บาท
              <br />
              <br />
            </p>
            <div v-html="previewData"></div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import TextEditor from '@/views/Message/components/TextEditor.vue'
import { GET_SETTING_MESSAGE, UPDATE_SETTING } from '@/resources/graphql'

export default {
  apollo: {
    setting: () => ({
      fetchPolicy: 'no-cache',
      query: GET_SETTING_MESSAGE,
      result ({ data: { setting } }) {
        if (setting.hideOrderSummaryTextInSummaryText === 'true') {
          this.form.hideOrderSummaryTextInSummaryText = true
        } else if (setting.hideOrderSummaryTextInSummaryText === 'false') {
          this.form.hideOrderSummaryTextInSummaryText = false
        } else {
          this.form.hideOrderSummaryTextInSummaryText = setting.hideOrderSummaryTextInSummaryText
        }

        this.form.orderSummaryText = setting.orderSummaryText
        this.form.orderLinkText = setting.orderLinkText
        this.previewData = setting.orderSummaryText

        this.defaultValue.orderLinkText = setting.orderLinkTextDefault

        this.setLoading(false)
      }
    })
  },
  components: {
    TextEditor
  },
  data () {
    return {
      previewData: null,
      state: {
        hideOrderSummaryTextInSummaryText: 'ready',
        orderSummaryText: 'ready',
        orderLinkText: 'ready'
      },
      form: {
        hideOrderSummaryTextInSummaryText: null,
        orderSummaryText: null,
        orderLinkText: null

      },
      defaultValue: {
        orderLinkText: null
      }
    }
  },
  mounted () {
    this.setLoading(true)
  },
  methods: {
    ...mapActions({
      setLoading: 'Notification/setLoading'
    }),
    preview (field) {
      this.previewData = this.form[field]
    },
    async submit (field) {
      this.state[field] = 'loading'
      const res = await this.$apollo.mutate({
        mutation: UPDATE_SETTING,
        variables: {
          input: {
            [field]: this.form[field]
          }
        }
      })

      if (res) {
        const { data: { updateSetting } } = res
        if (updateSetting.errors && Object.keys(updateSetting.errors).length) {
          this.state[field] = 'error'
          this.error = updateSetting.errors
        } else {
          this.state[field] = 'success'
        }
      }
    }
  }
}
</script>

<style scoped>
  .default-message {
    position: absolute;
    top: 0;
    right: 0;
  }
</style>

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-form", { ref: "form", attrs: { readonly: _vm.isSubmitting } }, [
    _c(
      "div",
      { staticClass: "d-flex justify-end" },
      [
        _vm.defaultValue
          ? _c(
              "v-btn",
              {
                staticClass: "mb-1",
                attrs: { rounded: "", text: "" },
                on: {
                  click: function($event) {
                    return _vm.setToDefault()
                  }
                }
              },
              [
                _c(
                  "v-icon",
                  { attrs: { color: "primary", left: "", small: "" } },
                  [_vm._v(" mdi-reload ")]
                ),
                _c("span", { staticClass: "primary--text" }, [
                  _vm._v(
                    " " + _vm._s(_vm.$t("message.btn.defaultMessage")) + " "
                  )
                ])
              ],
              1
            )
          : _vm._e()
      ],
      1
    ),
    _c("div", { class: { "tagify--disabled": _vm.disabled } }, [
      _c("div", { staticClass: "tagify__overlay--disabled" }),
      _c("textarea", {
        ref: "tags",
        domProps: { value: _vm.mixText },
        on: { change: _vm.onTagsChange }
      })
    ]),
    _c(
      "div",
      { staticClass: "d-flex justify-space-between mt-4" },
      [
        _vm._l(_vm.tags, function(tag) {
          return _c(
            "v-btn",
            {
              key: "txtwithtag_" + tag.value,
              staticClass: "mr-1",
              attrs: {
                color: "primary",
                label: "",
                small: "",
                outlined: "",
                disabled: _vm.disabled
              },
              on: {
                click: function($event) {
                  return _vm.addTag(tag)
                }
              }
            },
            [
              _c("v-icon", { attrs: { left: "", small: "" } }, [
                _vm._v(" mdi-plus-circle ")
              ]),
              _c("span", [_vm._v(_vm._s(tag.text))])
            ],
            1
          )
        }),
        _c("v-spacer"),
        _c(
          "div",
          [
            _c("save-change-button", {
              attrs: {
                state: _vm.state,
                label: _vm.$t("app.btn.save"),
                disabled: _vm.disabled
              },
              on: {
                click: function($event) {
                  return _vm.$emit("submit")
                }
              }
            })
          ],
          1
        )
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
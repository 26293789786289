var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-card",
        { staticClass: "mb-4", attrs: { flat: "" } },
        [
          _c(
            "v-card-text",
            [
              _c("title-label", {
                staticClass: "mb-4",
                attrs: { label: _vm.$t("message.face&line.title") }
              }),
              _vm._l(_vm.messageLists, function(message, i) {
                return _c(
                  "v-row",
                  { key: "tg_fb_line-" + i, attrs: { "no-gutters": "" } },
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "10" } },
                      [
                        _c("toggle-form", {
                          attrs: {
                            text: _vm.$t(message.text),
                            state: _vm.state[message.var]
                          },
                          on: {
                            input: function($event) {
                              return _vm.submit(message.var)
                            }
                          },
                          model: {
                            value: _vm.form[message.var],
                            callback: function($$v) {
                              _vm.$set(_vm.form, message.var, $$v)
                            },
                            expression: "form[message.var]"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              })
            ],
            2
          )
        ],
        1
      ),
      _c(
        "v-card",
        { attrs: { flat: "" } },
        [
          _c(
            "v-card-text",
            [
              _c(
                "div",
                { staticClass: "d-flex" },
                [
                  _c("title-label", {
                    staticClass: "mb-4",
                    attrs: { label: _vm.$t("message.sms&email.title") }
                  }),
                  _vm.isShipnity
                    ? _c(
                        "v-btn",
                        {
                          staticClass: "ml-4",
                          attrs: {
                            to: "/?pop=credit",
                            color: "primary",
                            outlined: ""
                          }
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.$t("message.btn.topUpSMS")) + " "
                          )
                        ]
                      )
                    : _vm._e()
                ],
                1
              ),
              _vm._l(_vm.smsLists, function(sms, i) {
                return _c(
                  "v-row",
                  { key: "tg_sms-" + i, attrs: { "no-gutters": "" } },
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "10" } },
                      [
                        _c("toggle-form", {
                          attrs: {
                            text: _vm.$t(sms.text),
                            state: _vm.state[sms.var]
                          },
                          on: {
                            input: function($event) {
                              return _vm.submit(sms.var)
                            }
                          },
                          model: {
                            value: _vm.form[sms.var],
                            callback: function($$v) {
                              _vm.$set(_vm.form, sms.var, $$v)
                            },
                            expression: "form[sms.var]"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              })
            ],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("header-label", {
        attrs: { label: _vm.$t("message.header"), "prepend-icon": "$g_message" }
      }),
      _c(
        "v-tabs",
        { staticClass: "app-tab", attrs: { "show-arrows": "" } },
        [
          _vm._l(_vm.tabHeaders, function(header) {
            return _c("v-tab", { key: header.id }, [
              _vm._v(" " + _vm._s(_vm.$t(header.title)) + " ")
            ])
          }),
          _c("v-tab-item", [_c("send-message")], 1),
          _c("v-tab-item", [_c("auto-message")], 1),
          _c("v-tab-item", [_c("order-message")], 1),
          _c("v-tab-item", [_c("cod-message")], 1)
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div>
    <header-label
      :label="$t('message.header')"
      prepend-icon="$g_message" />
    <v-tabs
      class="app-tab"
      show-arrows>
      <v-tab
        v-for="header in tabHeaders"
        :key="header.id">
        {{ $t(header.title) }}
      </v-tab>
      <v-tab-item>
        <send-message />
      </v-tab-item>
      <v-tab-item>
        <auto-message />
      </v-tab-item>
      <v-tab-item>
        <order-message />
      </v-tab-item>
      <v-tab-item>
        <cod-message />
      </v-tab-item>
    </v-tabs>
  </div>
</template>

<script>
import SendMessage from '@/views/Message/components/SendMessage.vue'
import AutoMessage from '@/views/Message/components/AutoMessage.vue'
import OrderMessage from '@/views/Message/components/OrderMessage.vue'
import CodMessage from '@/views/Message/components/CodMessage.vue'

export default {
  name: 'Message',
  components: {
    SendMessage,
    AutoMessage,
    OrderMessage,
    CodMessage
  },
  data () {
    return {
      tabHeaders: [
        {
          id: '1',
          title: 'message.tab.sendMessage'
        },
        {
          id: '2',
          title: 'message.tab.autoMessage'
        },
        {
          id: '3',
          title: 'message.tab.orderMessage'
        },
        {
          id: '4',
          title: 'message.tab.codMessage'
        }
      ]
    }
  }
}
</script>

<style>
  .v-window__container {
    background: #F1F2F5;
  }
</style>

<style scoped>

</style>

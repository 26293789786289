var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-form", { ref: "form", attrs: { readonly: _vm.isSubmitting } }, [
    _c(
      "div",
      { staticClass: "d-flex justify-end" },
      [
        _vm.defaultValue
          ? _c(
              "v-btn",
              {
                staticClass: "mb-1",
                attrs: { rounded: "", text: "" },
                on: {
                  click: function($event) {
                    return _vm.setToDefault()
                  }
                }
              },
              [
                _c(
                  "v-icon",
                  { attrs: { color: "primary", left: "", small: "" } },
                  [_vm._v(" mdi-reload ")]
                ),
                _c("span", { staticClass: "primary--text" }, [
                  _vm._v(
                    " " + _vm._s(_vm.$t("message.btn.defaultMessage")) + " "
                  )
                ])
              ],
              1
            )
          : _vm._e()
      ],
      1
    ),
    _c(
      "div",
      { class: { "mce--disabled": _vm.disabled } },
      [
        _c("div", { staticClass: "mce__overlay--disabled" }),
        _c("editor", {
          attrs: {
            value: _vm.value,
            "api-key": _vm.editorAPIKey,
            init: {
              setup: function(editor) {
                editor.ui.registry.addContextToolbar("textselection", {
                  predicate: function(node) {
                    return !editor.selection.isCollapsed()
                  },
                  items:
                    "forecolor | bold italic | \
          alignleft aligncenter alignright alignjustify | image | code",
                  position: "selection",
                  scope: "node"
                })
              },
              height: 150,
              menubar: false,
              statusbar: false,
              toolbar:
                "forecolor | bold italic | \
          alignleft aligncenter alignright alignjustify | image | code",
              plugins: [
                "advlist autolink lists link image charmap print preview anchor",
                "searchreplace visualblocks code fullscreen",
                "insertdatetime media table paste code help wordcount"
              ],
              fontsize_formats: "8px 10px 12px 14px 16px 18px 24px 36px 48px",
              body_class: "mce-readonly"
            },
            disabled: _vm.disabled
          },
          on: {
            onFocus: function($event) {
              return _vm.onEditorFocus($event)
            },
            input: function($event) {
              return _vm.onEditorChange($event)
            }
          }
        })
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "d-flex justify-space-between mt-4" },
      [
        _c("v-spacer"),
        _c(
          "div",
          [
            _c("save-change-button", {
              attrs: {
                state: _vm.state,
                disabled: _vm.disabled,
                label: _vm.$t("app.btn.save")
              },
              on: {
                click: function($event) {
                  return _vm.$emit("submit")
                }
              }
            })
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
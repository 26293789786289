<template>
  <v-form
    ref="form"
    :readonly="isSubmitting">
    <div class="d-flex justify-end">
      <v-btn
        v-if="defaultValue"
        class="mb-1"
        rounded
        text
        @click="setToDefault()">
        <v-icon
          color="primary"
          left
          small>
          mdi-reload
        </v-icon>
        <span class="primary--text">
          {{ $t('message.btn.defaultMessage') }}
        </span>
      </v-btn>
    </div>
    <div :class="{ 'mce--disabled': disabled }">
      <div class="mce__overlay--disabled"></div>
      <editor
        :value="value"
        :api-key="editorAPIKey"
        :init="{
          setup: function (editor) {
            editor.ui.registry.addContextToolbar('textselection', {
              predicate: function (node) {
                return !editor.selection.isCollapsed();
              },
              items: 'forecolor | bold italic | \
            alignleft aligncenter alignright alignjustify | image | code',
              position: 'selection',
              scope: 'node'
            });
          },
          height: 150,
          menubar: false,
          statusbar: false,
          toolbar: 'forecolor | bold italic | \
            alignleft aligncenter alignright alignjustify | image | code',
          plugins: [
            'advlist autolink lists link image charmap print preview anchor',
            'searchreplace visualblocks code fullscreen',
            'insertdatetime media table paste code help wordcount'
          ],
          fontsize_formats: '8px 10px 12px 14px 16px 18px 24px 36px 48px',
          body_class: 'mce-readonly'
        }"
        :disabled="disabled"
        @onFocus="onEditorFocus($event)"
        @input="onEditorChange($event)"

      />
    </div>
    <div class="d-flex justify-space-between mt-4">
      <v-spacer />
      <div>
        <save-change-button
          :state="state"
          :disabled="disabled"
          :label="$t('app.btn.save')"
          @click="$emit('submit')" />
      </div>
    </div>
  </v-form>
</template>

<script>
import Editor from '@tinymce/tinymce-vue'

export default {
  components: {
    Editor
  },
  props: {
    value: {
      type: String,
      default: ''
    },
    defaultValue: {
      type: String,
      default: ''
    },
    state: {
      type: String,
      validator: (val) => ['ready', 'loading', 'success', 'error'].includes(val),
      default: () => 'ready'
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      editorAPIKey: process.env.VUE_APP_TINYMCE_KEY
    }
  },
  computed: {
    isSubmitting () {
      return this.state === 'loading'
    }
  },
  methods: {
    setToDefault () {
      this.$emit('input', this.defaultValue)
    },
    onEditorChange (e) {
      this.$emit('input', e)
    },
    onEditorFocus (e) {
      this.$emit('focus', e)
    }
  }
}
</script>

<style>
  .mce--disabled {
    position: relative;
    background-color: #f1f2f5;
  }
  .mce--disabled .mce__overlay--disabled {
    position: absolute;
    background-color: #f1f2f5;
    opacity: 0.4;
    width: 100%;
    height: 100%;
    z-index: 2;
  }
  .textarea-editor fieldset {
    border-color: #ddd;
  }
</style>
